import React from "react";
import Typography from "@material-ui/core/Typography";

const FooterEx = () => (
  <div style={{ maxWidth: 700, margin: "auto", textAlign: "center" }}>
    <Typography variant="caption" align={"center"}>
    Story Place Preschool, Inc
    </Typography>
   <br></br>
   <Typography variant="caption" align={"center"}>
   1477 South Schodack Road Castleton, NY 12033
    </Typography>
   
  </div>
);

FooterEx.propTypes = {};
FooterEx.defaultProps = {};

export default FooterEx;
